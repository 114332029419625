<template>
  <div class="home">
    <main-page/>
  </div>
</template>

<script>

import MainPage from '@/components/MainPage'

export default {
  name: 'Home',
  components: {
    MainPage
  }
}
</script>

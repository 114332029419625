<template>
  <div>
    <q-list v-model="selected">
      <q-item v-for="user in users"
        :key="user.id"
        :active="selected === user"
        @click="select(user)"
        clickable
        v-ripple
        active-class="bg-primary text-white">
        <div v-if="user" class="row items-center">
          <q-icon class="q-px-md" name="fas fa-user"/>
          <div class="text-bold">
            {{user }}
          </div>
        </div>
      </q-item>
    </q-list>
    <q-space class="row"/>
  </div>
</template>

<script>
export default {
  props: {
    users: Array
  },
  data: function () {
    return {
      selected: null
    }
  },
  methods: {
    select (user) {
      this.selected = user;
      this.$emit('select', user)
    }
  }
}
</script>

<style scoped>

</style>
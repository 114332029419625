<template>
  <q-card>
    <q-card-section class="q-pa-lg">
      <div class="text-h5 q-pb-sm">{{title}}</div>
      <div v-if="windowsDownload" class="full-width q-pt-md">
        <q-btn size="lg" @click="downloadForWindows()" class="full-width" icon="fab fa-windows" no-caps unelevated rounded nocaps :label="windowsDownload.name" :color="getOS()=='Windows'? 'primary' : 'secondary'"/>
      </div>
      <div v-if="macOsDownload" class="full-width q-pt-md">
        <q-btn size="lg" @click="downloadForMac()" class="full-width" icon="fab fa-apple" no-caps unelevated rounded :label="macOsDownload.name" :color="getOS()=='Mac OS'? 'primary' : 'secondary'"/>
      </div>
    </q-card-section>

  </q-card>
</template>

<script>
import axios from 'axios'

export default {
  name: 'DownloadsPanel',
  data: function () {
    return {
      // downloads: [],
      windowsDownload: null,
      macOsDownload: null
    }
  },
  props: {
    appId: String,
    title: String
  },
  methods: {
    change () {
      try {
        if (this.appId) {
          axios
            .get('/api/app/' + this.appId + '/downloads', { headers: { 'Access-Control-Allow-Origin': '*', 'crossorigin': true }, preflight: false })
            .then(response => {
              var win = []
              var mac = []
              var downloads = response.data
              downloads.forEach(download => {
                if (download.os == 'windows') {
                  win.push(download)
                } else if (download.os == 'mac-os') {
                  mac.push(download)
                }
              })
              win.sort(a => a.id)
              mac.sort(a => a.id)
              this.windowsDownload = win.length > 0 ? win[0] : null
              this.macOsDownload = mac.length > 0 ? mac[0] : null
            })
        }
      } catch (e) {
        console.log('error loading downloads for: ' + this.appId)
      }
    },
    getOS () {
      var userAgent = window.navigator.userAgent,
        platform = window.navigator.platform,
        macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K'],
        windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE'],
        iosPlatforms = ['iPhone', 'iPad', 'iPod'],
        os = null;
      if (macosPlatforms.indexOf(platform) !== -1) {
        os = 'Mac OS';
      } else if (iosPlatforms.indexOf(platform) !== -1) {
        os = 'iOS';
      } else if (windowsPlatforms.indexOf(platform) !== -1) {
        os = 'Windows';
      } else if (/Android/.test(userAgent)) {
        os = 'Android';
      } else if (!os && /Linux/.test(platform)) {
        os = 'Linux';
      }
      return os;
    },
    downloadForWindows () {
      window.open('/api/app/' + this.appId + '/download/windows/' + this.windowsDownload.id, 'Download')
    },
    downloadForMac () {
      window.open('/api/app/' + this.appId + '/download/mac-os/' + this.macOsDownload.id, 'Download')
    }
  },
  mounted () {
    this.change()
  },
  watch: {
    'appId' () {
      this.change()
    }
  }
}
</script>

<style scoped>
</style>
<template>
  <div class="container row no-wrap">
    <div style="opacity: 0.0; width: 0px; height: 0px" class="col large-12 medium-12 small-12 cell">
      <input type="file" name="file" ref="file" v-on:change="handleFilesUpload()"/>
    </div>
    <q-space class="col-auto"/>
    <q-btn v-if="supportCaseId" class="col-grow" unelevated color="warning" v-on:click="addFiles()">Add File</q-btn>
  </div>
</template>

<style></style>

<script>
import Authorization from '../keycloak/Authorization'

export default {
  props: {
    supportCaseId: null
  },
  data () {
    return {
      files: []
    }
  },
  methods: {
    addFiles () {
      this.$refs.file.click();
    },
    submitFiles () {
      let formData = new FormData();
      for (let i = 0; i < this.$refs.file.files.length; i++) {
        let file = this.$refs.file.files[i];
        console.log('FORM FILE: ' + i + ': ' + JSON.stringify(file));
        formData.append('file', file);
        break;
      }
      console.warn('ADD FILE TO SEND - FORMDATA:  ' + JSON.stringify(formData) + ' file-count: ' + this.files.length)
      Authorization.postForm('/api/support/cases/' + this.supportCaseId + '/uploadFile', formData
      ).then(function (response) {
        if (!response.data) {
          alert('File not uploaded.');
        } else {
          alert('File uploaded successfully.');
        }
      })
        .catch(function (error) {
          console.log(error);
        });
    },
    handleFilesUpload () {
      this.files = this.$refs.file.files;
      if (this.files) {
        this.submitFiles()
      }
    },
    removeFile (key) {
      this.files.splice(key, 1);
    }
  }
}
</script>
<template>
  <div>
    <q-list>
      <q-item class="column" v-for="message in messages" :key="message.id">

        <div v-if="message.userId" class="row items-center">
          <q-icon class="q-px-md" name="fas fa-info-circle" color="primary"/>
          <div class="text-bold">
            {{message.userId}}
          </div>
        </div>

        <div v-if="message.userId" class="row items-center q-pl-sm">
          <div class="" v-if="message.message.startsWith('file://')">
            <img v-img class="q-pl-lg q-pt-sm" style="max-height: 257px;" :src="'/api/support_files/' + message.message.substr(7)">
          </div>
          <div v-else>
            <div class="q-pl-lg q-pt-sm">
              {{ message.message}}
            </div>
          </div>
        </div>

      </q-item>
    </q-list>
    <q-space class="row"/>
  </div>
</template>

<script>



export default {
  name: 'MessageList',
  props: {
    messages: Array
  }
}
</script>

<style scoped>

</style>
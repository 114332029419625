<template>
  <div class="row full-height">
    <UserList class="col" v-if="adminMode" :users="usersWithOpenCases" v-on:select="selectedCustomer=$event"/>
    <q-separator class="col-auto" style="min-width: 1px; width: 1px; max-width: 1px" v-if="adminMode" :vertical="true"/>
    <q-scroll-area class="col" style="width: 20em;">
      <q-list fill-width>
        <q-item
          v-if="!adminMode"
          key="create"
          clickable
          v-ripple
          :active="selectedSupportCase === null"
          @click="showCreateNew()"
          active-class="bg-primary text-white">
          <q-item-section avatar>
            <q-icon name="mdi-file-document-box"/>
          </q-item-section>
          <q-item-section class="text-bold">
            <div>
              New
            </div>
          </q-item-section>
          <q-item-section class="text-bold text-transparent">
            <div>
              |
            </div>
            <div class="text-weight-light">
              |
            </div>
          </q-item-section>
        </q-item>
        <div v-for="supportCase in supportCases" :key="supportCase.id">
          <q-item
            clickable
            v-ripple
            :active="selectedSupportCase === supportCase"
            @click="selectCase(supportCase)"
            active-class="bg-primary text-white">
            <q-item-section avatar>
              <q-icon name="mdi-file-document-box"/>
            </q-item-section>
            <q-item-section class="text-bold">
              <div>
                {{format(supportCase)}}
              </div>
              <div class="text-weight-light">
                {{new Date(supportCase.messages[0].timeStamp).toLocaleDateString()}}
              </div>
            </q-item-section>
            <q-item-section class="">
              <div>
                <q-badge color="warning" v-if="supportCase.status == 'OPEN'" label="OPEN"/>
              </div>
            </q-item-section>
          </q-item>
        </div>
      </q-list>
    </q-scroll-area>

    <q-separator class="col-auto" style="min-width: 1px; width: 1px; max-width: 1px" :vertical="true"/>
    <div class="col-8">
      <div class="column full-height">
        <SupportCaseTitle class="col-auto" v-if="!adminMode" :supportCase="selectedSupportCase"/>
        <TypeChooser class="col" v-if="!messages && !adminMode" v-on:typeChanged="typeToCreate = $event"/>
        <q-scroll-area class="col" v-if="messages">
          <MessageList :messages="messages"/>
          <!--          <q-scroll-observer @scroll="scrollHandler"/>-->
        </q-scroll-area>
        <AddSupportCaseMessagePage
          class="col q-px-md q-pb-md"
          :supportCase="selectedSupportCase"
          :typeOfSupportCaseInCreationMode="this.typeToCreate"
          v-on:added="messageAdded($event)"
          v-on:closeCase="closeCase()"
          v-on:reopenCase="reopenCase"/>
      </div>
    </div>
  </div>
</template>

<script>

import Authorization from '../../keycloak/Authorization'
import AddSupportCaseMessagePage from './AddSupportCaseMessagePage'
import MessageList from './MessageList'
import TypeChooser from './TypeChooser'
import SupportCaseTitle from './SupportCaseTitle'
import SupportCaseFormatter from '../../keycloak/SupportCaseFormatter'
import UserList from './UserList'

export default {
  components: {
    UserList,
    TypeChooser,
    MessageList,
    AddSupportCaseMessagePage,
    SupportCaseTitle
  },
  props: {
    adminMode: Boolean
  },
  data: function () {
    return {
      messages: null,
      usersWithOpenCases: null,
      selectedCustomer: null,
      selectedSupportCase: null,
      supportCases: Array,
      timer: null,
      createNew: false,
      typeToCreate: null,
    }
  },
  methods: {
    messageAdded (message) {
      if (this.selectedSupportCase) {
        Authorization.post('/api/support/cases/' + this.selectedSupportCase.id + '/add', { message: message })
      } else {
        Authorization.post('/api/support/cases/create', { message: message, type: this.typeToCreate })
      }
      const self = this
      setTimeout(function () {
        self.updateSupportCaseList()
      }, 1000)
      this.createNew = false
    },
    closeCase () {
      if (this.createNew) {
        return
      }
      Authorization.post('/api/support/cases/' + this.selectedSupportCase.id + '/state', { newStatus: 'CLOSED' })
      const self = this
      setTimeout(function () {
        self.updateSupportCaseList()
      }, 1000)
    },
    reopenCase () {
      if (this.createNew) {
        return
      }
      Authorization.post('/api/support/cases/' + this.selectedSupportCase.id + '/state', { newStatus: 'OPEN' })
      const self = this
      setTimeout(function () {
        self.updateSupportCaseList()
      }, 1000)
    },
    updateUserList () {
      if (!this.adminMode) {
        return
      }
      console.info('Updating usersWithOpenCases ... (selected case: ' + this.selectedSupportCase + ')')
      try {
        Authorization.get('/api/support/cases/users_with_open_cases', response => {
          let usersWithOpenCases = response.data
          this.usersWithOpenCases = usersWithOpenCases
        })
      } catch (e) {
        console.error(e)
      }
    },
    updateSupportCaseList () {
      console.info('Updating ... (selected case: ' + this.selectedSupportCase + ')')
      try {
        let url;
        if (this.adminMode) {
          if (!this.selectedCustomer) {
            return
          }
          url = '/api/support/cases/open/' + this.selectedCustomer
        } else {
          url = '/api/support/cases/own'
        }
        Authorization.get(url, response => {
          let newCases = response.data
          newCases = newCases.sort(function compare (a, b) {
            if (a.id > b.id) {
              return -1
            }
            if (a.id < b.id) {
              return 1
            }
            return 0
          })
          this.supportCases = newCases
          if (this.selectedSupportCase) {
            this.supportCases.forEach(value => {
              if (this.selectedSupportCase.id == value.id) {
                this.selectCase(value)
              }
            })
          } else {
            if (!this.createNew && this.supportCases.length > 0) {
              this.selectCase(this.supportCases[0])
            }
          }
        })
      } catch (e) {
        console.error(e)
      }
    },
    showCreateNew () {
      this.createNew = true
      this.selectCase(null)
    },
    selectCase (supportCase) {
      this.selectedSupportCase = supportCase
      this.messages = supportCase ? this.selectedSupportCase.messages : null
      if (supportCase == null) {
        this.typeToCreate = null;
      }
    },
    format (supportCase) {
      return SupportCaseFormatter.format(supportCase)
    }
  },
  mounted () {
    const self = this
    self.updateUserList()
    self.updateSupportCaseList()
    this.timer = setInterval(function () {
      self.updateUserList()
      self.updateSupportCaseList()
    }, 1000)
  },
  beforeDestroy () {
    clearInterval(this.timer)
  }
}
</script>

<style scoped>
</style>
<template>
    <div class="row vertical-middle full-width justify-center text-h3 q-pa-lg">
      <div class="text-grey text-weight-light row text-h4 q-pa-lg absolute-center">system maintenance - we will be back shortly ...</div>
    </div>
</template>

<script>
export default {}
</script>

<style scoped>

</style>
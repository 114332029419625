import * as Keycloak from 'keycloak-js'
import axiosInstance from 'axios'

const refreshCycle = 60
const initOptions = {
  url: window.location.protocol + '//' + window.location.host + '/auth',
  realm: 'Plaiiin',
  clientId: 'plaiiin-frontend',
  onLoad: 'login-required',
  promiseType: 'native'
}
const keycloak = Keycloak(initOptions);

function getConfig (contentType) {
  let config = {
    preflight: true
  }
  if (contentType) {
    config.headers = {
      'Authorization': 'BEARER ' + localStorage.getItem('vue-token'),
      'Content-Type': contentType
    }
  } else {
    config.headers = {
      'Authorization': 'BEARER ' + localStorage.getItem('vue-token')
    }
  }
  return config
}

function clearToken () {
  console.info('logout / clear token');
  localStorage.removeItem('vue-token');
  localStorage.removeItem('vue-refresh-token');
  keycloak.authenticated = false
  stopTokenRefresh()
}

let refreshing = false
let refreshingTimer = null

function ensureTokenRefreshing () {
  userInfo = keycloak.tokenParsed
  if (!refreshing) {
    console.info('START REFRESH TOKEN')
    refreshing = true
    refreshingTimer = setInterval(function () {
      keycloak.updateToken(refreshCycle)
    }, refreshCycle * 1000)
  }
}

function stopTokenRefresh () {
  if (refreshing) {
    console.info('STOP REFRESH TOKEN')
    refreshing = false
    clearInterval(refreshingTimer)
    refreshingTimer = null
  }
}

let userInfo = null
export default {
  getUserInfo () {
    return userInfo
  },
  async login () {
    if (keycloak.authenticated) {
      return;
    }
    console.info('Login ...')
    let success = await keycloak.init({ onLoad: initOptions.onLoad, promiseType: 'native' })
    if (!success) {
      throw 'Login failed'
    } else {
      localStorage.setItem('vue-token', keycloak.token);
      localStorage.setItem('vue-refresh-token', keycloak.refreshToken);
      userInfo = keycloak.tokenParsed
      ensureTokenRefreshing()
    }
  },
  logout () {
    console.info('Logout ...')
    try {
      keycloak.logout({ redirectUri: window.document.location.origin })
    } catch (e) {
      console.error(e)
    }
    clearToken()
    userInfo = null
  },
  async post (url, data) {
    await this.login()
    let config = getConfig(null)
    console.warn('CFG: ' + JSON.stringify(config))
    return axiosInstance.post(url, data, config).catch(async (e) => {
      console.error('failed to post() on the first try: ' + JSON.stringify(e))
      clearToken()
      await this.login()
      axiosInstance.post(url, data, config).catch(async () => {
        console.error('failed to post: ' + JSON.stringify(data) + ' to ' + url)
      })
    })
  },
  async postForm (url, data) {
    await this.login()
    let config = getConfig('multipart/form-data')
    console.warn('FROM CFG: ' + JSON.stringify(config))
    return axiosInstance.post(url, data, config)
  },
  async get (url, responseHandler) {
    let safeResponseHandler = value => {
      try {
        responseHandler(value)
      } catch (e) {
        console.error(e)
      }
    }
    await this.login()
    axiosInstance.get(url, getConfig(null)).then(safeResponseHandler, async (e) => {
      clearToken()
      console.error('failed to get() on the first try: ' + JSON.stringify(e))
      await this.login()
      axiosInstance.get(url, getConfig(null)).then(safeResponseHandler, async () => {
        console.error('failed to get: ' + url)
      })
    })
  }
  // ,
  // async upload (url, formData) {
  //   return axiosInstance.post(url, formData)
  //     // get data
  //     .then(x => x.data)
  //     // add url field
  //     .then(x => x.map(img =>
  //       Object.assign({}, img, { url: `${BASE_URL}/images/${img.id}` })
  //     ));
  // }
}




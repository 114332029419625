<template>
  <div class="q-py-lg full-width">
    <div class="q-pa-lg text-h6  ">
      How can we help?
    </div>
    <q-option-group
      @input="controlChanged()"
      class="row justify-evenly full-width"
      v-model="state"
      :options="options"
      color="primary"
      inline
      dense
    />
    <div class="q-pa-lg"/>
    <div class="q-pa-lg"/>
    <div class=" q-px-lg text-h6">
      Please enter your description below:
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      state: null,
      options: [
        {
          label: 'Report a Bug',
          value: 'BUG'
        },
        {
          label: 'Idea or Inspiration',
          value: 'IDEA'
        },
        {
          label: 'Help!',
          value: 'HELP'
        }
      ]
    }
  },
  methods: {
    controlChanged () {
      this.$emit('typeChanged', this.state)
    }
  }
}
</script>

<style scoped>

</style>
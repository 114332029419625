<template>
  <div class="col-auto">
    <q-input
      autogrow
      filled
      placeholder="Your message ..." hi class="col-grow q-pb-sm q-px-sm"
      v-model="message"
      type="textarea"
      v-if="!((typeOfSupportCaseInCreationMode==null && supportCase==null)||(supportCase!=null &&supportCase.status=='CLOSED'))"/>

    <div class="row" v-if="(supportCase==null || supportCase.status=='OPEN')">
      <div class="row justify-start">
        <UploadFiles v-if="supportCase" class="q-mx-sm q-mb-sm" :support-case-id="supportCase.id"/>
      </div>
      <div class="row col justify-end">
        <q-btn v-if="supportCase" unelevated color="warning" class="q-mx-sm q-mb-sm" @click="closeCase" :label="'Close this ' + supportCase.type"/>
        <q-btn v-if="typeOfSupportCaseInCreationMode || supportCase" :disable="typeOfSupportCaseInCreationMode==null && supportCase==null" unelevated color="primary" class="q-mr-sm q-mb-sm" @click="sendMessage" label="Send"/>
      </div>
    </div>
    <div class="row justify-end" v-else>
      <q-btn :disable="typeOfSupportCaseInCreationMode==null && supportCase==null" unelevated color="warning" class="q-mr-sm q-mb-sm" @click="reopenSupportCase" label="Reopen"/>
    </div>

  </div>
</template>

<script>

import UploadFiles from '../UploadFiles'

export default {
  components: {
    UploadFiles
  },
  props: {
    supportCase: null,
    typeOfSupportCaseInCreationMode: null
  },
  data: function () {
    return {
      message: ''
    }
  },
  methods: {
    closeCase () {
      this.$emit('closeCase')
      this.$emit('update:supportCase', { ...this.supportCase, status: 'CLOSED' })
    },
    sendMessage () {
      this.$emit('added', this.message)
      this.message = ''
    },
    reopenSupportCase () {
      this.$emit('reopenCase')
      this.$emit('update:supportCase', { ...this.supportCase, status: 'OPEN' })
    }
  },
  mounted () {
    let storedMessage = localStorage.getItem('message')
    if (storedMessage) {
      this.message = storedMessage;
    }
  },
  watch: {
    message: function () {
      localStorage.setItem('message', this.message)
    }
  }
}
</script>

<style scoped>
</style>
<template>
  <div v-if="supportCase" class="q-pa-lg text-h3 text-weight-light text-primary">
    {{format(supportCase)}}
  </div>
  <div v-else class="q-pa-lg text-h3 text-weight-light text-primary">
    Create new ...
  </div>
</template>

<script>
import SupportCaseFormatter from '../../keycloak/SupportCaseFormatter'

export default {
  props: {
    supportCase: null
  },
  methods: {
    format (supportCase) {
      return SupportCaseFormatter.format(supportCase)
    }
  }
}
</script>

<style scoped>

</style>
<template>
  <div class="row">
    <div class="col-4 q-pl-xl">
      <div class=" " style="border-radius: 16px; border-style: solid; border-width: 1px; border-color: lightgray">
        <div class="row justify-center q-pt-xl">
          <div class="row justify-center q-pt-xl">
            <img :src="'/api/app/'+ pageDefinition.id + '/app-icon.png'" width="128" height="128"/>
          </div>
        </div>
        <div class="row justify-center q-pt-sm q-pb-xl">
          <div class="text-h6">{{pageDefinition.title}}</div>
        </div>
        <div class="row justify-center q-pt-xl q-pb-lg">
          <q-btn label="Free Download!" no-caps unelevated rounded class="text-bold" color="primary" @click="showDownloadsDialog=true"/>
        </div>
      </div>
    </div>
    <div class="col-8">
      <div v-for="item in pageDefinition.content" v-bind:key="item.text" class="q-pb-md q-pt-sm q-px-xl">
        {{item.text}}
      </div>
    </div>

    <q-dialog v-model="showDownloadsDialog">
      <DownloadsPanel :app-id="pageDefinition.id" :title="'Download '+pageDefinition.title"/>
    </q-dialog>
  </div>
</template>

<script>

import DownloadsPanel from './DownloadsPanel'

export default {
  name: 'App',
  components: { DownloadsPanel },
  props: ['pageDefinition', 'icon'],
  created () {
    require('../assets/jenkins-status-app-icon.png')
    require.context('../assets', true)
  },
  data: function () {
    return {
      showDownloadsDialog: false
    };
  }
}
</script>

<style scoped>
</style>
<template>
  <div class="flex flex-center">
    <div class="full-width window-height">
      <div class="row justify-between q-pt-lg">
        <q-space/>
        <img alt="Quasar logo" src="../assets/header-logo.png"/>
        <q-space/>
        <div class="row items-center q-pb-lg q-px-lg">
          <q-btn v-if="!user" @click="login()" flat rounded color="primary" label="Login"/>
          <q-btn-dropdown v-if="user" flat rounded color="primary" :label="user.name">
            <q-list>
              <q-item v-if="user && user.admin" clickable v-close-popup>
                <q-item-section>
                  <q-item-label @click="showAdminDialog=true">Admin Panel ...</q-item-label>
                </q-item-section>
              </q-item>
              <q-item clickable v-close-popup>
                <q-item-section>
                  <q-item-label @click="logout">Logout</q-item-label>
                </q-item-section>
              </q-item>
            </q-list>
          </q-btn-dropdown>
        </div>
      </div>
      <q-separator color="primary" style="height: 2px; position: absolute" class="full-width q-ma-none"/>

      <div v-if="showMaintenance">
        <MaintenanceInfo/>
      </div>
      <div v-else>
        <div class="row q-ma-none">
          <q-tabs
            style="display:inline-block; background: transparent"
            inline-label
            switch-indicator
            class="q-pl-lg"
            v-model="selection"
            v-ripple="false"
            indicator-color="white"
            active-color="primary">
            <q-route-tab
              style="background: transparent"
              :ripple="false"
              v-for="app in apps"
              :name="app.id"
              :key="app.id"
              :to="'/'+app.id"
              :label="app.title"/>

            <q-route-tab
              style="background: transparent"
              :ripple="false"
              name="support"
              key="support"
              to="/support"
              label="Support"/>
          </q-tabs>
        </div>
        <div class="q-py-lg">
          <q-tab-panels v-model="selection" animated>
            <q-tab-panel class="q-pa-lg" v-for="app in apps" :name="app.id" :key="app.id">
              <AppPage :pageDefinition="app" icon="../assets/jenkins-status-app-icon.png"/>
            </q-tab-panel>
            <q-tab-panel name="support" key="support">
              ssssss
              <SupportCasePage class="fill-height full-width"/>
            </q-tab-panel>
            <q-tab-panel name="admin" key="admin">
              aaaaaaa
              <SupportCasePage admin-mode class="fill-height full-width"/>
            </q-tab-panel>
          </q-tab-panels>
        </div>
      </div>

    </div>
<!--    <q-dialog v-model="showSupportDialog">-->
<!--      <q-card style="width: 80%; max-width: 80%; min-width: 80%; min-height: 80%; height: 80%; max-height: 80%">-->
<!--        <SupportCasePage/>-->
<!--      </q-card>-->
<!--    </q-dialog>-->
<!--    <q-dialog-->
<!--      v-model="showAdminDialog"-->
<!--      type="maximized"-->
<!--      :maximized="maximizedToggle"-->
<!--      transition-show="slide-up"-->
<!--      transition-hide="slide-down">-->
<!--      <q-btn dense flat icon="close" v-close-popup class="absolute-top-right">-->
<!--        <q-tooltip content-class="bg-white text-primary">Close</q-tooltip>-->
<!--      </q-btn>-->
<!--      <q-card style="">-->
<!--      </q-card>-->
<!--    </q-dialog>-->
  </div>
</template>

<style>
body.desktop .q-focusable:focus .q-focus-helper,
body.desktop .q-hoverable:hover .q-focus-helper {
  background: transparent !important;
  opacity: 0 !important;
}

/*body.ios .q-hoverable:active .q-focus-helper {*/
/*  background: transparent !important;*/
/*  opacity: 0 !important;*/
/*}*/

/*.q-focus-helper {*/
/*  opacity: 0 !important;*/
/*  transition: unset !important;*/
/*}*/

/*.q-hoverable:hover {*/
/*  !*opacity: 0;*!*/
/*  background: white !important;*/
/*}*/
</style>

<script>
import AppPage from './AppPage'
import MaintenanceInfo from './MaintenanceInfo'
import SupportCasePage from './support/SupportCasePage'
import axios from 'axios'
import Authorization from '../keycloak/Authorization'

export default {
  components: {
    SupportCasePage,
    MaintenanceInfo,
    AppPage
  },
  data: function () {
    return {
      selection: null,
      showMaintenance: false,
      showSupportDialog: false,
      showAdminDialog: false,
      apps: [],
      user: null,
      userUpdateTimer: null,
      maximizedToggle: true
    }
  },
  methods: {
    logout () {
      Authorization.logout()
    },
    login () {
      Authorization.login()
    }
  },
  mounted () {
    axios
      .get('/api/app/', { headers: { 'Access-Control-Allow-Origin': '*', 'crossorigin': true }, preflight: false })
      .then(response => {
        return (this.apps = response.data)
      })
    this.showSupportDialog = 'true' === localStorage.getItem('showSupportDialog')
    this.showAdminDialog = 'true' === localStorage.getItem('showAdminDialog')
    console.info('MOUNTED showSupportDialog = ' + this.showSupportDialog)
    console.info('MOUNTED showAdminDialog = ' + this.showAdminDialog)
    const self = this
    this.userUpdateTimer = setInterval(function () {
      let usr = Authorization.getUserInfo()
      if (usr == null) {
        self.user = null
      } else {
        self.user = {
          name: usr.name,
          mail: usr.mail,
          admin: usr.realm_access.roles.includes('admin')
        }
      }
      console.debug('Current User: ' + self.user)
    }, 500)
  },
  beforeDestroy () {
    clearInterval(this.userUpdateTimer)
  },
  watch: {
    showSupportDialog: function () {
      localStorage.setItem('showSupportDialog', this.showSupportDialog)
      console.info('WATCH showSupportDialog = ' + this.showSupportDialog)
    },
    showAdminDialog: function () {
      localStorage.setItem('showAdminDialog', this.showAdminDialog)
      console.info('WATCH showAdminDialog = ' + this.showAdminDialog)
    }
  }
}
</script>
